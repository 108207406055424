
.radio-scale-component {
  background: var(--dark);
  border-radius: 5px;
  width: 230px;
  padding: 7px;

  & .input-group {
    & .input-group-prepend {

      & .input-group-text {
        border-color: var(--dark);
        background: #ddd;
      }
    }
    & .form-control {
      border-color: var(--dark);
      // width: 20px;
    }
  }
}
