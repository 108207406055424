
@import './assets/css/bootstrap.min';
@import './assets/scss/paper-kit.scss';
@import './surveys';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#topnav {
  margin-bottom: 50px;

  & .navbar-brand {
    padding-left: 0px;
  }
}

.pointer {
  cursor: pointer !important;
}

.btn[class*="btn-outline-"] {
  color: initial;
}

h3, .list-group-item-heading {
  font-weight: 400;
}
