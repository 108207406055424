
@import './UIComponents/AnswerTypes';

.status-dot {
  width: 10px;
  height: 10px;
  background: #ccc;
  border-radius: 100%;
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.list-group-item {
  position: relative;
}

.question-textarea {
  margin: 0 30px 0 0;
  height: 100px!important;
}

.answer-types {
  height: 110px;
  width: 242px;
  border-right: 1px solid #ccc;
  padding-right: 10px;
  margin-right: 20px;

  & .answer-type-select {
    width: 230px;
  }
}

.question-actions {
  padding-top: 15px;
  cursor: pointer;
  width: 40px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;

  & * {
    margin-bottom: 5px;
  }
}

.node-select-modal {
  & .modal-content {
    height: calc(100vh - 200px);

    & .modal-body {
      overflow-y: scroll;

      & .node {
        overflow: hidden;
        height: 10rem;
      }
    }
  }
}

.question-divider {
  background-color: var(--dark) !important;
  padding: 0;
  height: 12px;
  border-radius: 3px;
  margin: 15px 0;
  width: 100%;
  // position: relative;

  & h5 {
    z-index: 10;
    font-weight: normal;
    color: white;
    font-size: 14px;
    position: relative;
    top: -4px;
    background: var(--dark);
    width: 110px;
    margin: auto;
    border-radius: 3px;
  }

  & .move, & .delete {
    position: relative;
    top: -8px;
    padding: 0px 5px;
  }
  & .move {
    left: -15px;
  }
  & .delete {
    right: -15px;
  }
}
