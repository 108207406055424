
@import './UIComponents/AnswerTypes';

.status-dot {
  width: 10px;
  height: 10px;
  background: #ccc;
  border-radius: 100%;
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.question-textarea {
  margin: 0 30px 0 0;
  height: 100px!important;
}

.answer-types {
  height: 110px;
  border-right: 1px solid #ccc;
  padding-right: 10px;

  & .answer-type-select {
    width: 230px;
  }
}
.actions {
  // background: #eee;
  // padding: 10px;
  // position: relative;

  margin: -30px -10px 0 12px;
  height: 100%;

  & button {
    margin-bottom: 10px;
    padding: 3px;
    width: 30px;
  }
}

.note-select-modal {
  & .modal-content {
    height: calc(100vh - 200px);

    & .modal-body {
      overflow-y: scroll;

      & .note {
        overflow: hidden;
      }
    }
  }
}
